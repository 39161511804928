/* eslint-disable arrow-body-style */

/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'
import ParseContent from 'components/shared/ParseContent'
import Contact from 'components/sidebar/Contact'
import KoelMonteur from 'components/shared/before-footer/Koelmonteurs'

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageServiceDetail {
        servicedetailbanner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
        servicedetailtext {
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const ServiceDetailTemplate = ({
  data: {
    page: {
      seo,
      pageServiceDetail
    },
  },
}) => {
  return (
    <Layout>
      {seo && (
        <SEO seo={seo} socialImage={pageServiceDetail.servicedetailbanner.image.localFile.childImageSharp.meta.src} />
      )}

      <Hero src={pageServiceDetail.servicedetailbanner.image} title={pageServiceDetail.servicedetailbanner.title} />

      <div className="container py-5 mb-5">
        <div className="row">
          <div className="col-lg-6 pl-lg-5">
            <ParseContent content={pageServiceDetail.servicedetailtext.description} />
          </div>
          <Contact />
        </div>
      </div>
      <KoelMonteur />
    </Layout>
  )
}

export default ServiceDetailTemplate
